import React, { useState } from "react";
import classes from "./css/Urbandynamics.module.css";
import Linegraph from "../../modules/visualisations/components/LineGraph";

const UrbanDynamics = (props) => {
  const [graphType, setGraphType] = useState("total");
  const years = {
    1950: 0,
    1960: 1,
    1970: 2,
    1980: 3,
    1990: 4,
    2000: 5,
    2010: 6,
    2015: 7,
    2020: 8
  };

  const Tableheader = () => (
    <thead>
      <tr>
        {Object.keys(years).map((i, idx) => (
          <th key={idx}>{i}</th>
        ))}
      </tr>
    </thead>
  );

  const Totalpop = () => (
    <tr>
      {props.data.data.totalPop.map((i, idx) => (
        <td key={idx}>
          {(i)
            .toFixed()
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
        </td>
      ))}
    </tr>
  );

  const Totalpopgrowth = () => (
    <tr className={classes.growth}>
      <td></td>
      {props.data.data.totalPopGrowth.map((i) => (
        <td key={i}>{Math.round(i * 1000) / 10 + "%"}</td>
      ))}
    </tr>
  );

  const Urbanpop = () => (
    <tr>
      {props.data.data.urbanPop.map((i, idx) => (
        <td key={idx}>
          {(i)
            .toFixed()
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
        </td>
      ))}
    </tr>
  );

  const Urbanpopgrowth = () => (
    <tr className={classes.growth}>
      <td>Annual growth rate</td>
      {props.data.data.urbanPopGrowth.map((i, idx) => (
        <td key={idx}> {">" + Math.round(i * 1000) / 10 + "%"}</td>
      ))}
    </tr>
  );

  const Ruralpop = () => (
    <tr>
      {props.data.data.ruralPop.map((i, idx) => (
        <td key={idx}>
          {(i)
            .toFixed()
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
        </td>
      ))}
    </tr>
  );

  const Ruralpopgrowth = () => (
    <tr className={classes.growth}>
      <td>Annual growth rate</td>
      {props.data.data.ruralPopGrowth.map((i, idx) => (
        <td key={idx}>{">" + Math.round(i * 1000) / 10 + "%"}</td>
      ))}
    </tr>
  );

  let regionselected = [];
  props.regionlist.map((e) =>
    e["C_ID"] === props.data.id[1]
      ? (regionselected = props.regiondata[53 + e["R_ID"]])
      : null
  );

  return (
    <div className={classes.urbandynamics}>
      <div className={classes.buttonrow}>
        <button
          className={
            graphType === "total" ? classes.buttonActive : classes.button
          }
          key={"total"}
          onClick={() => setGraphType("total")}
        >
          Total population
        </button>
        <button
          className={
            graphType === "urban" ? classes.buttonActive : classes.button
          }
          key={"urban"}
          onClick={() => setGraphType("urban")}
        >
          Urban population
        </button>
        <button
          className={
            graphType === "rural" ? classes.buttonActive : classes.button
          }
          key={"rural"}
          onClick={() => setGraphType("rural")}
        >
          Rural population
        </button>
      </div>
      <div
        className={graphType === "total" ? classes.popdiv : classes.hiddendiv}
      >
        <table className={classes.table}>
          <Tableheader />
          <tbody>
            <Totalpop />
            <Totalpopgrowth />
          </tbody>
        </table>
        <div className={classes.graph}>
          <Linegraph
            data={props.data.data.totalPop}
            title={"Evolution of total population"}
            country={props.data.title}
            region={regionselected.data.totalPop}
            regionTitle={regionselected.title}
            africa={props.regiondata[61].data.totalPop}
          />
        </div>
      </div>
      <div
        className={graphType === "urban" ? classes.popdiv : classes.hiddendiv}
      >
        <table className={classes.table}>
          <Tableheader />
          <tbody>
            <Urbanpop />
            <Urbanpopgrowth />
          </tbody>
        </table>
        <div className={classes.graph}>
          <Linegraph
            data={props.data.data.urbanPop}
            title={"Evolution of urban population"}
            country={props.data.title}
            region={regionselected.data.urbanPop}
            regionTitle={regionselected.title}
            africa={props.regiondata[61].data.urbanPop}
          />
        </div>
      </div>
      <div
        className={graphType === "rural" ? classes.popdiv : classes.hiddendiv}
      >
        <table className={classes.table}>
          <Tableheader />
          <tbody>
            <Ruralpop />
            <Ruralpopgrowth />
          </tbody>
        </table>
        <div className={classes.graph}>
          <Linegraph
            data={props.data.data.ruralPop}
            title={"Evolution of rural population"}
            country={props.data.title}
            region={regionselected.data.ruralPop}
            regionTitle={regionselected.title}
            africa={props.regiondata[61].data.ruralPop}
          />
        </div>
      </div>
    </div>
  );
};

export default UrbanDynamics;
