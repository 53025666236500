import React from "react";
import { pushQuery } from "../../global";
import classes from "./css/TimeSlider.module.css";

const TimeSlider = ({ disabled, label, width, history, query }) => {
  return (
    <div style={{ width: `${width}px` }} className={classes["input-slider"]}>
      <input
        type="range"
        min={1950}
        max={2020}
        disabled={disabled}
        value={query.year}
        list ="years"
        step={5}
        onChange={(e) => {
            if (["1950", "1960", "1970", "1980", "1990", "2000", "2010", "2015", "2020"].includes(e.target.value)){
                pushQuery(history, {
                    year: e.target.value,
                })
            }
        }
        }
      />
        <datalist id="years" className={classes["datalist"]}>
            <option value="1950" label="1950"/>
            <option value="1960" label="1960"/>
            <option value="1970" label="1970"/>
            <option value="1980" label="1980"/>
            <option value="1990" label="1990"/>
            <option value="2000" label="2000"/>
            <option value="2010" label="2010"/>
            <option value="2015" label="2015"/>
            <option value="2020" label="2020"/>
        </datalist>

      {label && <span>{query.year}</span>}
    </div>

  );
};

export default TimeSlider;
