import React, { useState, useEffect } from "react";
import { Viz } from "../../visualisations/index";
import classes from "./css/ExploreMixer.module.css";
import { AsyncSelect, SelectorStyles, TimeSlider } from "../../selectors";
import {
  getUnit,
  formatNumber,
} from "../../visualisations/components/MapComponents/mapFunctions";
import { pushQuery } from "../../global";
import {Icon} from "../../static";
import {LinkItem} from "../../headers/header";
import Popup from "reactjs-popup";
import 'reactjs-popup/dist/index.css';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WeiboShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WeiboIcon,
} from "react-share";


export default ({ countryList, data, query, history, locale, open, layer, setLayer, buildUpYear, setBuildUpYear }) => {
  const [viewType, setViewType] = useState("country");
  useEffect(() => {
    if (query.agglomeration === "" || !query.agglomeration) {
      setViewType("country");
    } else {
      setViewType("agglomeration");
    }
  }, [query.agglomeration]);

  // Set View Types
  const viewTypes = locale==="en"? [
    { id: "country", label: "Country/Region" },
    { id: "agglomeration", label: "Agglomeration" },
  ] : [
    { id: "country", label: "Pay/Région" },
    { id: "agglomeration", label: "Agglomération" },
  ]
  // check if selectedCountry ID is for country or region
  const isCountry = data.selectedCountry.id[1] === 0 ? false : true;
  const isCountryIdx = isCountry ? 1 : 2;
  const isRegionalEntity = data.selectedCountry.id[1] === 0 && data.selectedCountry.id[2] > 6
  const isAfrica = !isCountry && data.selectedCountry.id[2] === 6;
  console.log(data.selectedCountry)

  // Make a copy of country data
  const tempCountryData = [...data.countryData];
  const tempAgglosData = [...data.agglosData];
  const selectedName =
      viewType === "country"
          ? data.selectedCountry.title
          : data.selectedAgglos.name;

  // Define variables and id for types of bar chart

  let countryBarTypes = locale==="en"?  [
    { id: "urbanPop", label: "Urban population", unit: "million" },
    { id: "urbanLevel", label: "Level of urbanisation", unit: "percentage" },
    { id: "numAgglos", label: "Number of agglomerations", unit: "number" },
    { id: "mPop", label: "Metropolitan population", unit: "percentage" },
    { id: "ADBA", label: "Avg distance between agglomerations", unit: "km" },
    // { id: "source"},
    { id: "uSurf", label: "Urban land cover", unit: "percentage" },
  ] : [
    { id: "urbanPop", label: "Population urbaine", unit: "million" },
    { id: "urbanLevel", label: "Niveau d'urbanisation", unit: "percentage" },
    { id: "numAgglos", label: "Nombre d'agglomérations", unit: "number" },
    { id: "mPop", label: "Population métropolitaine", unit: "percentage" },
    { id: "ADBA", label: "Distance moyenne entre les agglomérations", unit: "km" },
    // { id: "source"},
    { id: "uSurf", label: "Couverture terrestre urbaine", unit: "percentage" },
  ]

  let agglosBarTypes = locale==="en"? [
    { id: "uPop", label: "Population", unit: "" },
    { id: "density", label: "Density", unit: "Inhabs/km2" },
    { id: "builtUp", label: "Built-up area", unit: "area" },
    {
      id: "distToMetro",
      label: "Distance to metropolitan agglomeration",
      unit: "km",
    },
    { id: "voronoi", label: "Size of Voronoi cell", unit: "area" },
  ] : [
    { id: "uPop", label: "Population", unit: "" },
    { id: "density", label: "Densité", unit: "Inhabs/km2" },
    { id: "builtUp", label: "Bâti urbain", unit: "area" },
    {
      id: "distToMetro",
      label: "Distance vers la métropole",
      unit: "km",
    },
    { id: "voronoi", label: "Taille de la cellule de Voronoï", unit: "area" },
  ]

  // Retrieve year index based on year query
  // const baseYear = 1950;
  // const currYear = Math.round((Number(query.year) - baseYear) / 10);
  const currYear = ["1950", "1960", "1970", "1980", "1990", "2000", "2010", "2015", "2020"].indexOf(query.year)


  // If query year is not 2015, exclude uSurf from barTypes
  if (query.year !== "2015" && query.year !== "2020") {
    countryBarTypes.pop();
    agglosBarTypes = agglosBarTypes.filter((d) => d.id === "uPop");
  }

  // Make a copy of the original variable array and add an empty data object
  const countryBarChartData = [...countryBarTypes];
  countryBarChartData.forEach((d) => (d.data = []));
  const agglosBarChartData = [...agglosBarTypes];
  agglosBarChartData.forEach((d) => (d.data = []));

  /**
   * FILTER COUNTRY / REGION DATA
   * filter and append barchart data to countryBarTypes array
   */
  const COUNTRY_DATA = isCountry
      ? tempCountryData.splice(0, 54)
      : isRegionalEntity
      ? tempCountryData.splice(60, 9)
      : tempCountryData.splice(54, 6);

  COUNTRY_DATA.forEach((country) => {
    countryBarChartData.forEach((type) => {
      // if(type.id!=="source"){
      let idx =
          isCountry && type.id !== "uSurf"
              ? currYear
              : !isCountry && type.id !== "uSurf"
                  ? currYear
                  : currYear<7
                      ? currYear
                      : currYear-7;  //Because uSurf only exist after 2015, the index of 2015 in the list is 0.
      type.data.push({
        country: country.title,
        value: country.data[type.id][idx],
      });
    // }
  });
  });
  countryBarChartData.forEach((d) => {
    d.data.sort((a, b) => a.value - b.value);
  });
  /**
   * FILTER Agglomeration data
   * filter and append barchart data to agglosBarTypes array
   */
  function filterAgglos(d) {
    let result = !isAfrica && !isRegionalEntity
        ? d.ID[isCountryIdx] === data.selectedCountry.id[isCountryIdx]
        : d;


    if(isRegionalEntity){
      if (d.RegionalEntities_ID){
      result = d.RegionalEntities_ID.includes(data.selectedCountry.id[2])}
      else result = false;
    }
    return result;
  }

  const AGGLOS_DATA = tempAgglosData.filter(filterAgglos);
  AGGLOS_DATA.forEach((agglos) => {
    agglosBarChartData.forEach((type) => {
      let value =
          type.id === "uPop"
              ? agglos[type.id][currYear]
              : ['builtUp', 'density', 'voronoi'].includes(type.id)
                  ? agglos[type.id][currYear-7]
                  : agglos[type.id];
      type.data.push({
        agglomeration: agglos.name,
        value: value,
      });
    });
  });

  agglosBarChartData.forEach((d) => {
    d.data.sort((a, b) => a.value - b.value);
  });

  const options = computeOptions(countryList);

  function handleViewType(type) {
    if (type === "agglomeration" && !data.selectedAgglos) return;
    setViewType(type);
  }
  function handleSearchPush(e) {
    if (e.value[1] === 0){
        let newQuery = { country: e.label }
        pushQuery(history, newQuery);
    }
    else{
      let newQuery = { agglomeration: e.label.substring(0, e.label.indexOf(",")),
        country: e.label.substring(e.label.indexOf(",")+2) }
      pushQuery(history, newQuery);
    }
  }

  function getValue(data, type) {
    console.log(data)
    let result = data.filter((d) => d[viewType] === selectedName)[0].value;
    let unit = getUnit(type);
    let formattedResult = formatNumber(result, type);
    return `${formattedResult} ${unit}`;
  }

  function getRank(data) {
    let result = data.findIndex((d) => d[viewType] === selectedName);
    return data.length - result;
  }

  let barChartData =
      viewType === "country" ? countryBarChartData : agglosBarChartData;

  const shareUrl = window.location.href;
  const title = locale === "en" ?
      'Explore data for more than 9,200 urban agglomerations in 54 African countries on Africapolis. '
      : 'Explorez plus de 9 200 agglomérations urbaines au sein de 51 pays africains sur Africapolis.';


  return (
      <section className={open ? classes.ExploreMixerOpen : classes.ExploreMixerClosed}>
        <div className={classes.backshare}>

          <a className={classes.ExploreNavToggle} href={`/${locale}/countries`}>
            <Icon icon="arrow-left"  />
          </a>

          <Popup
              trigger={<div className={classes.ExploreNavToggle}> <Icon icon="share"  /> </div>}
              modal
              nested
          >
            {close => (
                <div className={classes.modal}>
                  <button className={classes.close} onClick={close}>
                    &times;
                  </button>
                  <div className={classes.header}> {locale==="en" ? "Share this page to your social media"  : "Partager cette page sur vos réseaux sociaux"} </div>
                  <div className={classes.content}>

                    <div> {locale == "en" ? "Link to share: " : "Lien à partager : "}</div>

                    <div>

                    <input type="text" readonly value={shareUrl}/>

                      <button className={[classes.viewType__item, classes.viewType__item_selected].join(" ")}
                              onClick={() => {
                                navigator.clipboard.writeText(shareUrl).then(() => {
                                  alert(locale === "en" ? "Link copied to clipboard!" : "Lien copié dans le presse-papiers !");
                                });
                              }}>
                        {locale == "en" ? "Copy this link" : "Copier ce lien"}
                      </button>


                    </div>

                    <div>

                    <FacebookShareButton
                        url={shareUrl}
                        quote={title}
                    >
                      <FacebookIcon size={32} round />
                    </FacebookShareButton>

                    <TwitterShareButton
                        url={shareUrl}
                        title={title + "@AfricapolisSWAC"}
                    >
                      <TwitterIcon size={32} round />
                    </TwitterShareButton>


                    <LinkedinShareButton url={shareUrl}>
                      <LinkedinIcon size={32} round />
                    </LinkedinShareButton>

                    <WeiboShareButton
                        url={shareUrl}
                        title={title}
                        image={`./image/main/explore.png`}
                    >
                      <WeiboIcon size={32} round />
                    </WeiboShareButton>

                      </div>



                  </div>
                  <div className={classes.actions}>
                  </div>
                </div>
            )}
          </Popup>

        </div>

        <div className={classes.ExploreSearch}>
          <AsyncSelect
              filterVariable={
                query.agglomeration ? query.agglomeration : query.country
              }
              styles={SelectorStyles.DefaultStyle}
              options={options}
              push={(e) => handleSearchPush(e)}
              query={query}
          />
          <div>
            <div className={classes.BuiltUpArea}>
              <div className={classes.CriterionHeader}><span> {locale=="en" ? "Built-up area" : "Zone bâtie" } </span></div>
              <button
                  className={
                    buildUpYear === 2015
                        ? [classes.viewType__item, classes.viewType__item_selected].join(" ")
                        : classes.viewType__item
                  } onClick={() => setBuildUpYear(2015)}
              >
                2015
              </button>
              <button
                  className={
                    buildUpYear === 2020
                        ? [classes.viewType__item, classes.viewType__item_selected].join(" ")
                        : classes.viewType__item
                  } onClick={() => setBuildUpYear(2020)}
              >
                2020
              </button>
            </div>


            <div className={classes.Layer}>
              <div className={classes.CriterionHeader}> {locale=="en" ? "Layer"  : "Couche"} </div>
              <div className={classes.LayerButtons}>
                <button className={
                  layer === "base"
                      ? [classes.viewType__item, classes.viewType__item_selected].join(" ")
                      : classes.viewType__item
                } onClick={() => setLayer("base")}

                >Base</button>

                {locale==="en" ? <button            className={
                  layer === "nightlight"
                      ? [classes.viewType__item, classes.viewType__item_selected].join(" ")
                      : classes.viewType__item
                }
                                   onClick={() => {
                                     setLayer("nightlight");
                                   }}
                >{locale=="en" ? "Nightlight" : "Éclairage nocturne"}</button>
                : <button            className={
                  layer === "nightlight"
                      ? [classes.viewType__item_fr, classes.viewType__item_selected].join(" ")
                      : classes.viewType__item_fr
                }
                                   onClick={() => {
                                     setLayer("nightlight");
                                   }}
                >{locale=="en" ? "Nightlight" : "Éclairage nocturne"}</button>}

                {locale==="en" ? <button            className={
                  layer === "satellite"
                      ? [classes.viewType__item, classes.viewType__item_selected].join(" ")
                      : classes.viewType__item
                }
                                   onClick={() => setLayer("satellite")}
                >{locale=="en" ? "Satellite image" : "Image satellite"}</button>
                    : <button            className={
                      layer === "satellite"
                          ? [classes.viewType__item_fr, classes.viewType__item_selected].join(" ")
                          : classes.viewType__item_fr
                    }
                                         onClick={() => setLayer("satellite")}
                    >{locale=="en" ? "Satellite image" : "Image satellite"}</button>}
              </div>
            </div>

            <div className={classes.UrbanPopulationStatistics}>
              <div className={classes.CriterionHeader}> {locale==="en" ? "Urban population statistics" : "Statistiques démographiques urbaines"} </div>

              <div className={classes.ExploreTimeslider}>
                <TimeSlider height={10} margin={10} query={query} history={history} />
                <span>{query.year}</span>
              </div>

            </div>

          </div>

          <div className={classes.ExploreMixerSelectors}>
            {viewTypes.map((d, i) => (
                <div
                    key={i}
                    className={
                      viewType === d.id
                          ? [classes.ExploreMixerSelector, classes.selected].join(" ")
                          : classes.ExploreMixerSelector
                    }
                    onClick={() => handleViewType(d.id)}
                >
                  {d.label}
                </div>
            ))}
          </div>
          <div className={classes.ExploreDivider}></div>
        </div>

        <div className={classes.BarWrapper}>
          <div className={classes.visible}>
            {barChartData.map((d, i) => {
              console.log(barChartData)
              return (
                  <div className={classes.HistogramItems} key={i}>
                    <div className={classes.HistogramHeader}>
                      <span>{d.label}</span>
                      <span>{getValue(d.data, d.unit)}</span>
                    </div>
                    {!isAfrica && (
                        <div className={classes.HistogramContent}>
                          <div className={classes.CountryRanking}>
                            <p>
                              Rank: <br />
                              <span
                                  style={{
                                    color:
                                        viewType === "country" ? "#e8ae40" : "#c3533e",
                                  }}
                              >
                          {getRank(d.data)}
                        </span>
                              /{d.data.length}
                            </p>
                          </div>
                          <div className={classes.bar}>
                            <Viz.BarGraph
                                key={`${viewType}-${i}`}
                                type={viewType}
                                bardata={d}
                                query={query}
                                history={history}
                            />
                          </div>
                        </div>
                    )}
                  </div>
              );
            })}
          </div>
        </div>
        Source: {data.countryData.filter(item => item.title == query.country)[0].data.source}
      </section>
  );
};

function computeOptions(d) {
  let computed = d.structure.map((item) => ({
    label:
        item[1] !== 0
            ? d.agglomerations[item[1] - 1] + ", " + d.countries[item[0] - 1]
            : d.countries[item[0] - 1],
    value: item,
  }));

  for (let region in d.regions) {
    computed.splice(region, 0, {
      label: d.regions[region],
      value: [parseInt(region), 0, 0],
    });
  }
  return computed;
}
