import React from "react";
import DataTable, { createTheme } from "react-data-table-component";
import classes from "./css/TableData.module.css";
import SearchBar from "./graphComponents/SearchBar/SearchBar";
import { pushQuery } from "../../global";
export default ({ data, keyFigureList, query, history, locale }) => {
  createTheme("solarized", {
    text: {
      primary: "rgba(0,0,0,.99)",
      secondary: "#2aa198",
    },

    context: {
      background: "#cb4b16",
      text: "#fff",
    },
    divider: {
      default: "rgba(0,0,0,.10)",
    },
    action: {
      button: "rgba(0,0,0,.54)",
      hover: "rgba(70,0,0,.99)",
      disabled: "rgba(0,0,0,.12)",
    },
    highlightOnHover: {
      default: "#e3e3e3",
      text: "#000",
    },
  });

  let nodataText = locale==="en"? "There are no records to display" : "Il n'y a pas d'enregistrement à afficher"
  let countryListFR = ["Angola","Burundi","Bénin","Burkina Faso","Botswana","République centrafricaine","Côte d’Ivoire","Cameroun","République démocratique du Congo","République du Congo","Comores","Cabo Verde","Djibouti","Algérie","Égypte","Érythrée","Éthiopie","Gabon","Ghana","Guinée","Gambie","Guinée-Bissau","Guinée équatoriale","Kenya","Libéria","Libye","Lesotho","Maroc","Madagascar","Mali","Mozambique","Mauritanie","Maurice","Malawi","Mayotte","Namibie","Niger","Nigéria","Réunion","Rwanda","Soudan","Sénégal","Sierra Leone","Somalie","Soudan du Sud","Sao Tomé-et-Principe","Royaume d’Eswatini","Seychelles","Tchad","Togo","Tunisie","Tanzanie","Ouganda","Afrique du Sud","Zambie","Zimbabwe","Afrique centrale","Afrique de l'Est","Afrique du Nord","Afrique australe","Afrique de l'Ouest","Afrique",
    'Union du Maghreb arabe',
    'Marché commun de l’Afrique orientale et australe',
    'Communauté des États Sahélo Sahariens',
    'Communauté d’Afrique de l’Est',
    'Communauté économique des États de l’Afrique centrale',
    'Communauté économique des États de l’Afrique de l’Ouest',
    'Autorité Intergouvernementale sur le Développement',
    'Communauté de développement de l’Afrique australe',
    'Union monétaire ouest-africaine'];
  let countryData = [];
  data.countryData.forEach((d,j) =>
    Object.keys(d.data).forEach((e) =>
      query.keyfigure === e
        ? locale==="en"? countryData.push(["urbanLevel", "mPop"].includes(e) ? {
            Name: d.title,
            Variable: e,
            1950: (d.data[e][0]*100).toFixed(2)+"%",
            1960: (d.data[e][1]*100).toFixed(2)+"%",
            1970: (d.data[e][2]*100).toFixed(2)+"%",
            1980: (d.data[e][3]*100).toFixed(2)+"%",
            1990: (d.data[e][4]*100).toFixed(2)+"%",
            2000: (d.data[e][5]*100).toFixed(2)+"%",
            2010: (d.data[e][6]*100).toFixed(2)+"%",
            2015: (d.data[e][7]*100).toFixed(2)+"%",
            2020: (d.data[e][8]*100).toFixed(2)+"%",

          }:{
            Name: d.title,
            Variable: e,
            1950: d.data[e][0],
            1960: d.data[e][1],
            1970: d.data[e][2],
            1980: d.data[e][3],
            1990: d.data[e][4],
            2000: d.data[e][5],
            2010: d.data[e][6],
            2015: d.data[e][7],
            2020: d.data[e][8],

          }) : countryData.push(["urbanLevel", "mPop"].includes(e) ? {
            Name: countryListFR[j],
            Variable: e,
            1950: (d.data[e][0]*100).toFixed(2)+"%",
            1960: (d.data[e][1]*100).toFixed(2)+"%",
            1970: (d.data[e][2]*100).toFixed(2)+"%",
            1980: (d.data[e][3]*100).toFixed(2)+"%",
            1990: (d.data[e][4]*100).toFixed(2)+"%",
            2000: (d.data[e][5]*100).toFixed(2)+"%",
            2010: (d.data[e][6]*100).toFixed(2)+"%",
            2015: (d.data[e][7]*100).toFixed(2)+"%",
            2020: (d.data[e][8]*100).toFixed(2)+"%",

          }:{
            Name: countryListFR[j],
            Variable: e,
            1950: d.data[e][0],
            1960: d.data[e][1],
            1970: d.data[e][2],
            1980: d.data[e][3],
            1990: d.data[e][4],
            2000: d.data[e][5],
            2010: d.data[e][6],
            2015: d.data[e][7],
            2020: d.data[e][8],

          })
        : null
    )
  );

  const Export = ({ onExport }) => (
    <button
      className={classes.button}
      onClick={(e) => onExport(e.target.value)}
    >
      {locale==="en"? "Download" : "Télécharger"}
    </button>
  );

  const region = locale==="en"? [
    "Central Africa",
    "East Africa",
    "North Africa",
    "Southern Africa",
    "West Africa",
    "Africa",
    "Arab Maghreb Union",
    "Common Market of Eastern and Southern Africa",
    "Community of Sahel-Saharan States",
    "East African Community",
    "Economic Community of Central African States",
    "Economic Community of West African States",
    "Intergovernmental Authority on Development",
    "Southern African Development Community",
    "West African Economic and Monetary Union"
  ] : [ "Afrique centrale","Afrique de l'Est","Afrique du Nord","Afrique australe","Afrique de l'Ouest","Afrique",
    'Union du Maghreb arabe',
    'Marché commun de l’Afrique orientale et australe',
    'Communauté des États Sahélo Sahariens',
    'Communauté d’Afrique de l’Est',
    'Communauté économique des États de l’Afrique centrale',
    'Communauté économique des États de l’Afrique de l’Ouest',
    'Autorité Intergouvernementale sur le Développement',
    'Communauté de développement de l’Afrique australe',
    'Union monétaire ouest-africaine']




  const columns = [
    {
      name: <h2>Name</h2>,
      selector: "Name",
      sortable: true,
      cell: (row) => (
        <b>
          {region.includes(row.Name) ? (
            <span style={{ color: "#a70000" }}>{row.Name}</span>
          ) : (
            row.Name
          )}
        </b>
      ),
    },
    {
      name: <h2>1950</h2>,
      selector: "1950",
      sortable: true,
    },
    {
      name: <h2>1960</h2>,
      selector: "1960",
      sortable: true,
    },
    {
      name: <h2>1970</h2>,
      selector: "1970",
      sortable: true,
    },
    {
      name: <h2>1980</h2>,
      selector: "1980",
      sortable: true,
    },
    {
      name: <h2>1990</h2>,
      selector: "1990",
      sortable: true,
    },
    {
      name: <h2>2000</h2>,
      selector: "2000",
      sortable: true,
    },
    {
      name: <h2>2010</h2>,
      selector: "2010",
      sortable: true,
    },
    {
      name: <h2>2015</h2>,
      selector: "2015",
      sortable: true,
    },
    {
      name: <h2>2020</h2>,
      selector: "2020",
      sortable: true,
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
  };

  const keyfigureOptions = keyFigureList.map((d) => ({
    value: d.type,
    label: d["label"],
    description: d["description"],
  }));

  return (
    <div>
      <div className={classes.TableHeader}>
        <div className={classes.TableSearch}>
          <SearchBar
            isMulti={false}
            query={query}
            queryType="keyfigure"
            options={keyfigureOptions}
            onSearchChange={(e) => pushQuery(history, { keyfigure: e.value })}
          />
        </div>
        <Export onExport={() => downloadCSV(countryData)} />
      </div>
      <DataTable
        columns={columns}
        noDataComponent={nodataText}
        data={countryData}
        highlightOnHover={true}
        dense={true}
        noHeader={true}
        theme="solarized"
        customStyles={customStyles}
      />
    </div>
  );
};

function downloadCSV(array) {
  const link = document.createElement("a");
  const keys = Object.keys(array[0]);
  let csv = convertArrayOfObjectsToCSV(array, keys);
  if (csv == null) return;
  const filename = "export.csv";
  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }
  link.setAttribute("href", encodeURI(csv));
  link.setAttribute("download", filename);
  link.click();
}

function convertArrayOfObjectsToCSV(array, keys) {
  let result;
  const columnDelimiter = ",";
  const lineDelimiter = "\n";
  result = "";
  result += keys.join(columnDelimiter);
  result += lineDelimiter;
  array.forEach((item) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;
      result += item[key];
      ctr++;
    });
    result += lineDelimiter;
  });
  return result;
}
