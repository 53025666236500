import { isoList } from "./mapUtils";

export function formatNumber(value, type) {
  let result =
    type === "percentage"
      ? Math.round(value * 10000) / 100
      : type === "million"
      ? value
          .toFixed()
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
      : value
          .toFixed()
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return result;
}

export function getUnit(type) {
  switch (type) {
    case "percentage":
      return "%";
    case "km":
      return "km";
    case "Inhabs/km2":
      return "Inhabs/km²";
    case "area":
      return "km²";
    default:
      return "";
  }
}
export const idToISO3 = (id) => isoList[id];
export function setAgglosColor(year) {
  return [
    "step",
    ["get", `Population_${year}`],
    "hsl(0, 0%, 8%)",
    10000,
    "#602317",
    30000,
    "#A02F3F",
    100000,
    "#CB5260",
    300000,
    "#E38479",
    1000000,
    "#F1B78C",
    2000000,
    "#F8DCA0",
    11847635,
    "#F8DCA0",
  ];
}

export function setAgglosColorDistance(year) {
  return [
    "step",
    ["get", `Distance to Metro`],
    "hsl(0, 0%, 8%)",
    100,
    "#602317",
    200,
    "#A02F3F",
    300,
    "#CB5260",
    400,
    "#F1B78C",
  ];
}

export function setAgglosRadius(year) {
  return [
    "step",
    ["get", `Population_${year}`],
    0,
    10000,
    3,
    30000,
    6,
    100000,
    9,
    300000,
    12,
    1000000,
    15,
    2000000,
    18,
    11847635,
    21,
  ];
}

// Loop through config and add source
export function addSources(config, map) {
  const sources = config.map((d) => ({
    source: d.source,
    type: d.type,
    url: d.url,
  }));
  for (let i in sources) {
    let PromotedName = sources[i].source.includes("country")? "NAME_EN" : "Name"
    map.addSource(sources[i].source, {
      type: sources[i].type,
      url: sources[i].url,
      promoteId: PromotedName
    });
  }
}

// Loop through config and add Layer
export function addLayers(config, map) {
  const layers = config.reduce((acc, obj) => {
    obj.feature
      ? acc.push({
          id: obj.id,
          source: obj.source,
          type: obj.layerType,
          "source-layer": obj.sourceLayer,
          ...obj.feature,
        })
      : acc.push({
          id: obj.id,
          source: obj.source,
          type: obj.layerType,
          "source-layer": obj.sourceLayer,
        });
    return acc;
  }, []);
  for (let i in layers) {
    map.addLayer(layers[i]);
  }
}

// Loop through config. Check if layer has mounse event (for mouseover and mouseleave) then add.
export function onMouseHover(config, map, enterCallback, leaveCallback) {
  if (!config) return;
  const events = config
    .filter((c) => c.mouseEvent === true)
    .map((d) => ({
      enter: "mouseenter",
      leave: "mouseleave",
      id: d.id,
      source: d.source,
      sourceLayer: d.sourceLayer,
    }));
  for (let i in events) {
    map.on(events[i].enter, events[i].id, (value) =>
      enterCallback(value, events[i].source, events[i].sourceLayer)
    );
    map.on(events[i].leave, events[i].id, (_) =>
      leaveCallback(events[i].source, events[i].sourceLayer)
    );
  }
}
