import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ContainerType } from "../../modules/layout";
import {GridWrapper, GridContainer, Grid} from "../../modules/grids";
import * as actions from "../../store/actions";
import TableData from "../../modules/visualisations/components/TableData";
import classes from "./css/Countries.module.css";
import Layout from "../../modules/layout/components/graph/Layout";
import {libraryItem} from "../../modules/grids/components/GridComponents";
// import LayoutContainer from "../../modules/layout/components/graph/LayoutContainer";

export default (props) => {
  ///// data table

  const [selectedMenu, setSelectedMenu] = useState("browser");

  const { locale } = props.match.params;
  const { history, q } = props;
  const dispatch = useDispatch();
  const data = useSelector((state) => state.data.data);
  const dataCountry = useSelector((state) => state.countries);

  const stableDispatch = useCallback(() => {
    dispatch(actions.fetchDataData(locale));
  }, [locale, dispatch]);

  useEffect(() => {
    return stableDispatch();
  }, [stableDispatch]);

  const stableDispatchInitialFetch = useCallback(() => {
    dispatch(actions.initialFetchCountryData(locale));
  }, [locale, dispatch]);

  // Fetch country data once.
  useEffect(() => {
    stableDispatchInitialFetch();
    return () => actions.clearCountriesData();
  }, [stableDispatchInitialFetch]);

  let keyFigureList = locale==="en"? [
    {
      "ID": 1,
      "type": "totalPop",
      "label": "Total population",
      "description": "Total number of people in the country"
    },
    {
      "ID": 2,
      "type": "urbanPop",
      "label": "Urban population",
      "description": "Total number of people living in rural agglomerations"
    },
    {
      "ID": 3,
      "type": "ruralPop",
      "label": "Rural population",
      "description": "Total number of people living in urban agglomerations"
    },
    {
      "ID": 4,
      "type": "Upop",
      "label": "Population by agglomeration size class",
      "description": "Total number of people living in urban agglomerations by size"
    },
    {
      "ID": 5,
      "type": "urbanLevel",
      "label": "Level of urbanisation",
      "description": "Share of the urban population in total population"
    },
    {
      "ID": 6,
      "type": "numAgglos",
      "label": "Number of agglomerations",
      "description": "Total number of urban agglomerations in country"
    },
    {
      "ID": 7,
      "type": "mPop",
      "label": "Metropolitan population",
      "description": "Share of metropolitan population in total urban population"
    }
  ] : [
    {
      "ID": 1,
      "type": "totalPop",
      "label": "Population totale",
      "description": "Nombre total de personnes vivant dans les agglomérations urbaines"
    },
    {
      "ID": 2,
      "type": "urbanPop",
      "label": "Population urbaine",
      "description": "Nombre total de personnes vivant dans les agglomérations urbaines"
    },
    {
      "ID": 3,
      "type": "ruralPop",
      "label": "Population rurale",
      "description": "Nombre total de personnes vivant dans les agglomérations urbaines"
    },
    {
      "ID": 4,
      "type": "Upop",
      "label": "Population par taille des agglomérations",
      "description": "Nombre total de personnes vivant dans les agglomérations urbaines"
    },
    {
      "ID": 5,
      "type": "urbanLevel",
      "label": "Niveau d'urbanisation",
      "description": "Part de la population métropolitaine dans la population totale"
    },
    {
      "ID": 6,
      "type": "numAgglos",
      "label": "Nombre d'agglomérations",
      "description": "Nombre total d'agglomérations dans le pays"
    },
    {
      "ID": 7,
      "type": "mPop",
      "label": "Population métropolitaine",
      "description": "Part de la population métropolitaine dans la population totale"
    }
  ]

  const loaded = Object.keys(data).length > 0;
  const countryDataLoaded = dataCountry.countryData.length > 0;
  const downloads = locale==="en"? [
    {
      label: "Country data",
      link: "http://eepurl.com/iooDTA",
    },
    {
      label: "Agglomeration data",
      link: "http://eepurl.com/iooDTA",
    },
    {
      label: "GIS",
      link: "http://eepurl.com/iooDTA",
    }
  ] : [
    {
      label: "Données par pays",
      link: "http://eepurl.com/iooDTA",
    },
    {
      label: "Données par agglomération",
      link: "http://eepurl.com/iooDTA",
    },
    {
      label: "SIG",
      link: "http://eepurl.com/iooDTA",
    },
  ]

  if (!loaded) return <div></div>;

  return (
    <ContainerType.Text
      {...props}
      data={data}
      selectedMenu={selectedMenu}
      toggleMenu={(value) => setSelectedMenu(value)}
      border
    >
      <GridWrapper>
        <GridContainer style={classes.Containerss}>
          {!countryDataLoaded ? (
            <div> Loading </div>
          ) : selectedMenu === "browser" ? (
            <Layout
              data={dataCountry}
              locale={locale}
              query={q}
              keyFigureList={keyFigureList}
              history={history}
            />
          ) : selectedMenu === "table" ? (
            <TableData
              data={dataCountry}
              locale={locale}
              query={q}
              keyFigureList={keyFigureList}
              history={history}
            />
          ) : selectedMenu === "download" ?(
            <div>
              {downloads.map((d) => (
                <div key={d.label} className={classes.download__inner}>
                  <span>{d.label}</span>

                  {(d.label === 'GIS' || (locale === 'fr' && d.label === 'SIG')) ? (
                    <div className={classes.download__item}>
                      <a href={d.link} className={classes.download__item}>{locale==="en"? "Download - 2015" : 'Télécharger - 2015'}</a>
                      <a href={d.link} className={classes.download__item}>{locale==="en"? "Download - 2020" : 'Télécharger - 2020'}</a>
                    </div>
                  ) : (
                    <div className={classes.download__item}>
                      <a href={d.link}>{locale==="en"? "Download" : 'Télécharger'}</a>
                    </div>
                  )}                

                </div>
              ))}
              <div className={classes.download__inner}><span>{locale==="en"? "Suggested citation: OECD/SWAC (2024), Africapolis (database), www.africapolis.org (accessed "  + new Date().toISOString().split('T')[0]  + ")." : 'Citation suggérée: OCDE/CSAO (2024), Africapolis (base de données), www.africapolis.org (consultée le '  + new Date().toISOString().split('T')[0] +').'} </span></div>
            </div>
          ) : selectedMenu === "library" ?(<>

                  <GridContainer
                      style={classes.Container}
                      header
                      title={data.brochures.title}
                      type="twelve"
                  >
                    <Grid.libraryItem
                        data={data.brochures.list}
                        style={classes.Item}
                    />
                  </GridContainer>

              <GridContainer
                  style={classes.Container}
                  header
                  title={data.postcard.title}
                  type="twelve"
              >
                <Grid.libraryItem
                    data={data.postcard.list}
                    style={classes.Item}
                />
              </GridContainer>
                  </>

              )
              :(
            <div>
            <span>Africapolis has been designed to provide a much needed standardised and geospatial database on urbanisation dynamics in Africa, with the aim of making urban data in Africa comparable across countries and across time. This version of Africapolis is the first time that the data for the 54 countries currently covered are available for the same base year — 2015. In addition, Africapolis closes one major data gap by integrating 7,496 small towns and intermediary cities between 10,000 and 300,000 inhabitants.

Africapolis data is based on a large inventory of housing and population censuses, electoral registers and other official population sources, in some cases dating back to the beginning of the 20th century. While essential for building any database, the regularity, the detail and the reliability of these sources vary from country to country, and from period to period. Census data or official population records are often more than 10 years old and, in certain cases, go back 30 or more years. Given the pace of demographic and urban change these are significant periods.

Satellite and aerial images are then used to fill in and complete the gaps and inconsistencies in official records, by capturing the physical evidence on the ground, that is the built-up area and the precise location of settlements. Other official cartographic resources, such as administrative boundaries, are also used to link population data to observed information on the built-up areas.

The teams working on Africapolis, at e-Geopolis and at the OECD Sahel and West Africa Club, have worked for years to build the Africapolis database, learning during the process, adding new sources and improving on the tools and methodology used to make the data as precise as possible.

Africapolis will remain an ongoing endeavour, providing data and evidence to support cities and governments to make urban areas more inclusive, productive and sustainable. We will keep looking for new ways, new tools and new data to improve Africapolis and its relevance for the African continent and invite you to contribute.</span>
            </div>
          )}
        </GridContainer>
      </GridWrapper>
    </ContainerType.Text>
  );
  // }
};
