import React from "react";
import { LinkItem } from "../../headers/header/index";
import { TextLink, Image } from "../../static/index";
import classes from "./css/GridComponents.module.css";

export const Grid = ({ match, data, type, baseURL, main }) =>
  data &&
  data.map((d, idx) => {
    let renderElement = null;
    switch (d.type) {
      case "article":
        renderElement = (
          <LinkItem
            type={d.type === "article" ? "container" : ""}
            url={`${match}/${d.url}`}
          >
            <Image
              imageWrapper={[
                classes.GridImage,
                main ? classes.mainimage : "",
              ].join(" ")}
              src={`${baseURL}images/${d.image}.png`}
              alt={d.title}
            />
            <div className={classes.GridContent}>
              <h4>{d.title}</h4>
              <p>{d.description}</p>
            </div>
          </LinkItem>
        );
        break;
      case "video":
        renderElement = (
          <div key={`video-${idx}`} className={classes.GridVideo}>
            <iframe
              src={d.video}
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
              title="video"
            />
          </div>
        );
        break;
      case "press":
        renderElement = (
          <LinkItem url={d.url} item={d.title}>
                        <Image
              imageWrapper={[
                classes.GridImage,
                main ? classes.mainimage : "",
              ].join(" ")}
              src={`${baseURL}images/${d.image}.png`}
              alt={d.title}
            />
            <h4>{d.title}</h4>
            <p>{d.description}</p>
          </LinkItem>
        );
        break;
      case "event":
          renderElement = (
              <LinkItem
                  type="containerDirectLink"
                  url={d.url}
              >
                  <Image
                      imageWrapper={[
                          classes.GridImage,
                          main ? classes.mainimage : "",
                      ].join(" ")}
                      src={`${baseURL}images/events/${d.image}`}
                      alt={d.title}
                  />
                  <div className={classes.GridContent}>
                      <p>{d.date} {d.location}</p>
                      <h5>{d.title}</h5>
                  </div>
              </LinkItem>
          );
        break;
      default:
        renderElement = null;
    }
    return (
      <article
        key={idx}
        className={[
          classes.Grid,
          classes[type[idx]],
          main ? classes.main : "",
        ].join(" ")}
      >
        {renderElement}
      </article>
    );
  });

export const List = ({ type, data, locale, style }) =>
  data &&
  data.map((d, idx) => (
    <div key={idx} className={style}>
      <LinkItem
        url={
          type === "report"
            ? { pathname: `/${locale}/country-report/${d.url ? d.url : d.item}` }
            : type === "explore"
            ? {
                pathname: `/${locale}/explore`,
                search: `?country=${
                  d.url ? d.url : d.item
                }&poprange=1,2,3,4,5,6&year=2020`,
              }
            : {
                pathname: `/${locale}/graph`,
                search: `?country=${d.item}&poprange=1,2,3,4,5,6&year=2020`,
              }
        }
        type="container"
        style={d.disabled ? classes.item__disabled : ""}
      >
        {d.item}
      </LinkItem>
    </div>
  ));

export const Research = ({ data, match, style }) =>
  data &&
  data.map((d, idx) => (
    <div key={idx} className={style}>
      <LinkItem url={d.url.substring(0,4)=="http"? d.url :`${match}/${d.url}`} type={d.url.substring(0,4)=="http"?"containerDirectLink":"container"}>
        {d.image ? (
          <div className={classes.GridImage}>
            <img src={`/images/research/${d.image}.png`} alt={d.title} />
          </div>
        ) : null}
        <h4>{d.title}</h4>
        <p>{d.subTitle}</p>
      </LinkItem>
    </div>
  ));

export const AboutNarratives = ({ data, match, style, baseURL }) =>
  data &&
  data.map((d, idx) => (
    <div key={idx} className={style}>
      <LinkItem url={`${match}/${d.url}`} type="container">
        <div className={classes.GridImage}>
          <img src={`/images/about/${d.image}.png`} alt={d.title} />{" "}
        </div>
        <h4>{d.subTitle}</h4>
      </LinkItem>
    </div>
  ));

export const AboutNewsletter = ({ data, match, style, baseURL }) =>
    data &&
    data.map((d, idx) => (
        <div key={idx} className={style}>
            <LinkItem url={`${d.url}`} type="containerDirectLink">
                <div className={classes.GridNewsletterImage}>
                    <img src={`/images/about/newsletter/${d.image}`} alt={d.title} />{" "}
                </div>
                <h4>{d.subTitle}</h4>
                <p>{d.description}</p>
            </LinkItem>
        </div>
    ));

export const libraryItem = ({ data, style }) =>
    data &&
    data.map((d, idx) => (
        <div key={idx} className={style}>
            <LinkItem url={`${d.url}`} type="containerDirectLink">
                <div className={classes.GridLibraryImage}>
                    <img src={`/images/library/postcards/${d.image}`} alt={d.title} />{" "}
                </div>
                <h4>{d.subTitle}</h4>
            </LinkItem>
        </div>
    ));

export const About = ({ data, images, style }) => {
  return (
    <div className={classes.AboutContainer}>
      <div className={classes.AboutTextContainer}>
        {data.map((d, idx) => {
          let tempLinks = {};
          for (let item in d.links)
            tempLinks[d.links[item].match] = d.links[item].link;
          return (
            <div key={idx} className={style}>
              <TextLink key={idx} text={d.item} links={tempLinks} />
            </div>
          );
        })}
      </div>
      <div className={classes.AboutImageContainer}>
        {images && (
          <div className={classes.AboutImage}>
            <div className={classes.MembersWrapper}>
              {images.map((image) => {
                let sm = ["ECOWAS", "UEMOA", "cliss", "france", "SDG-11"];
                let img = image.split(".")[0];
                let width = sm.includes(img) ? "73%" : "95%";
                return (
                  <div key={image} className={classes.ImageMembers}>
                    <img
                      className={classes.GridImageAbout}
                      style={{ width: width }}
                      src={`https://www.africapolis.org/images/${image}`}
                      alt={image}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

// {d.image ? (
//         Array.isArray(d.image) ? (
//           <div
//             className={
//               d.members
//                 ? classes.GridImageAboutMembersWrap
//                 : classes.GridImageAboutMultiple
//             }
//           >
//             {d.image.map((e) => (
//               <div
//                 className={
//                   d.members
//                     ? classes.GridImageAboutBox
//                     : classes.GridImageAboutMultiple
//                 }
//               >
//                 <img
//                   className={
//                     d.members
//                       ? classes.GridImageAboutMembers
//                       : classes.GridImageAboutMultiple
//                   }
//                   src={"https://www.africapolis.org/" + e}
//                   alt={d.title}
//                 />
//               </div>
//             ))}
//           </div>
//         ) : (
//           <div className={classes.GridImageAbout}>
//             <img
//               className={classes.GridImageAbout}
//               src={"https://www.africapolis.org/" + d.image}
//               alt={d.title}
//             />
//           </div>
//         )
//       ) : null}
