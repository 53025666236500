import React, { useEffect } from "react";
import { useMap } from "../../../customHooks";
import { agglomeration_main } from "./MapComponents/mapConfig";
import { idToISO3, setAgglosColor } from "./MapComponents/mapFunctions";
import classes from "./css/CountryReportMap.module.css";
import { bBoxById } from "./MapComponents/mapUtils";
const CountryReportMap = ({ selectedCountry }) => {
  const config = {
    mapConfig: {
      interactive: false,
    },
    tiles: [
      {
        id: "agglomerations",
        source: "africapolis_agglos",
        sourceLayer: "africapolis2020-clhcur",
        type: "vector",
        layerType: "circle",
        url: "mapbox://mkmd.3nrl0izs",
        mouseEvent: false,
        feature: {
          paint: agglomeration_main.paint,
        },
      },
    ],
  };
  const { map, mapContainerRef } = useMap(config);

  useEffect(() => {
    if (!map) return;
    let countryId = selectedCountry.id[1];
    let countryType = idToISO3(countryId);
    let bound = [
      [bBoxById[countryId][0][0] - 6, bBoxById[countryId][0][1]],
      [bBoxById[countryId][1][0], bBoxById[countryId][1][1]],
    ];
    map
      .fitBounds(bound)
      .setFilter("agglomerations", [
        "all",
        ["==", "ISO3", countryType],
        [">", `Population_2015`, 0],
      ])
      .setPaintProperty(
        "agglomerations",
        "circle-color",
        setAgglosColor("2015")
      )
      .setPaintProperty(
        "agglomerations",
        "circle-stroke-color",
        setAgglosColor("2015")
      );
  }, [map, selectedCountry]);
  return <div ref={mapContainerRef} className={classes.cr_map__wrapper} />;
};

export default CountryReportMap;
