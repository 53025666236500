import React from "react";
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  Tooltip,
  Legend,
  Line,
} from "recharts";
import classes from "../../../containers/components/css/Urbandynamics.module.css";

export default ({ data, country, region, regionTitle, africa, locale }) => {
  const years = {
    1950: 0,
    1960: 1,
    1970: 2,
    1980: 3,
    1990: 4,
    2000: 5,
    2010: 6,
    2015: 7,
    2020: 8
  };

  let data_line = [];

  data.forEach((i, j) =>
    data_line.push({
      id: "Total population",
      year: Number(Object.keys(years)[j]),
      [country]: (i / data[4]) * 100,
      [regionTitle]: (region[j] / region[4]) * 100,
      Africa: (africa[j] / africa[4]) * 100,
    })
  );

  const renderTooltip = (props) => {
    if (props.active && props.payload !== null && props.payload[0] !== null) {
      let payload = props.payload[0].payload;
      let tooltip = null;

      tooltip = (
        <div className={classes.Tooltip}>
          <p style={{ marginLeft: "10px", marginRight: "10px" }}>
            {payload.year} <small>(Index 1990 = 100x)</small>{" "}
          </p>
          {props.payload.map((i, idx) => (
            <p
              key={idx}
              style={{
                color: i.color,
                marginLeft: "10px",
                marginRight: "10px",
              }}
            >
              {i.dataKey}: {Math.round(i.value)}x
            </p>
          ))}
        </div>
      );
      return tooltip
    }
    return null;
  };

  return (
    <div style={{ width: "100%" }}>
      <ResponsiveContainer height={400} width="100%">
        <LineChart
          height={400}
          data={data_line}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" horizontal={false} />
          <XAxis
            dataKey="year"
            domain={[1950, 2020]}
            type="number"
            ticks={[1950, 1960, 1970, 1980, 1990, 2000, 2010, 2015, 2020]}
          />
          {/* <YAxis type='number'  strokeWidth="0px" stroke='black' tick={{fontSize: '11px'}} /> */}
          {/* <Tooltip /> */}
          <Tooltip
            cursor={{ strokeWidth: 0 }}
            content={renderTooltip}
            type={data.ID}
            language={locale}
          />
          <Line
            type="monotone"
            dataKey={country}
            stroke="#c5543e"
            strokeWidth={2}
          />
          <Line
            type="monotone"
            dataKey={regionTitle}
            stroke="#6791cc"
            strokeWidth={2}
          />
          <Line
            type="monotone"
            dataKey="Africa"
            stroke="#aad7a7"
            strokeWidth={2}
          />
          <Legend />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};
