import React from "react";
import { popRangeArray, colorsByPopRange } from "./MapComponents/mapUtils";
import { pushQuery } from "../../global";
import classes from "./css/Legend.module.css";

export default ({ query, history, selectedCountry, data }) => {
  const { year, poprange } = query;

  let idx = selectedCountry.id[1] === 0 ? 2 : 1;
  let isAfrica = idx === 2 && selectedCountry.id[2] === 6;
  let isRegionalEntity = selectedCountry.id[2] > 6

  let tempAgglos = [...data];
  let rangeArray = [],
    countArray = [];

  let range = Array.from(poprange.replace(/,/g, ""));
  let radius = 3;
  let len = radius * (colorsByPopRange.length + 1);

  let baseYear = 1950,
    currYear = Number(year);
  let yearIdx = [1950, 1960, 1970, 1980, 1990, 2000, 2010, 2015, 2020].indexOf(currYear)
  let interval = popRangeArray.map((d) => d.range);

  function filterAgglos(d) {
    let result = !isAfrica && !isRegionalEntity ? d.ID[idx] === selectedCountry.id[idx] : d;

    if(isRegionalEntity){
      if (d.RegionalEntities_ID){
        result = d.RegionalEntities_ID.includes(selectedCountry.id[2])}
      else result = false;
    }
    return result;
  }
  tempAgglos
    .filter(filterAgglos)
    .forEach((d) => countArray.push(d.uPop[yearIdx]));

  let count = getCountPerRange(interval, countArray);
  popRangeArray.forEach((d, idx) => {
    if (range.includes(d.id.toString())) {
      rangeArray.push({ ...d, active: true });
    } else {
      rangeArray.push({ ...d, active: false });
    }
  });
  rangeArray.reverse();
  return (
    <div className={classes.Legend}>
      <div className={classes.LegendContent}>
        <table className={classes.LegendTable}>
          <tbody>
            {rangeArray.map((d, i) => {
              let rvsIdx = rangeArray.length - 1 - i;
              let aggloMeta = count.filter((d) => d.id === rvsIdx)[0];
              let symbolDim = len - i * radius;
              return (
                <tr
                  key={i}
                  className={classes.LegendRow}
                  style={{ opacity: !d.active || !aggloMeta ? 0.2 : 1 }}
                  onClick={() => handleLegendItems(range, history, d.id)}
                >
                  <td className={classes.LegendSymbolWrapper}>
                    <div
                      style={{
                        width: `${symbolDim}px`,
                        height: `${symbolDim}px`,
                        background: colorsByPopRange[i],
                      }}
                      className={classes.LegendSymbol}
                    ></div>
                  </td>
                  <td>{d.label}</td>
                  <td>{aggloMeta ? `${aggloMeta.count}` : ""}</td>
                  <td>
                    {aggloMeta
                      ? `${Math.round(aggloMeta.pop / 100000) / 10}M`
                      : ""}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

function handleLegendItems(p, h, e) {
  let selected = e.toString();
  if (p.includes(selected)) {
    if (p.length === 1) return;
    pushQuery(h, { poprange: p.filter((d) => d !== selected) });
  } else {
    p.push(selected);
    pushQuery(h, { poprange: p });
  }
}

function getCountPerRange(range, arr) {
  let result = [];
  for (let i in arr) {
    let pop = arr[i];
    for (let r in range) {
      let min = range[r][0],
        max = range[r][1];
      let id = Number(r);
      if (min <= pop && pop < max) {
        let exists = result.map((d) => d.id).includes(id);
        if (!exists) {
          result.push({ id: id, count: 1, pop: pop });
        } else {
          let curr = result.filter((d) => d.id === id)[0];
          curr.count += 1;
          curr.pop += pop;
        }
      }
    }
  }
  return result;
}
