import React from "react";
import { Image } from "../../static";
import classes from "./css/Footer.module.css";
import {
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  EmailIcon,
} from "react-share";

export default (props) => {
  const { baseURL, match } = props;
  let locale = match.params.locale;
  let text = props.i18n.footer;
  let shareIcons = [
    { Icon: EmailIcon, href: "http://eepurl.com/iooDTA" },
    { Icon: TwitterIcon, href: "https://twitter.com/AfricapolisSWAC" },
    { Icon: FacebookIcon, href: "http://www.facebook.com/OECDSWAC" },
    {
      Icon: LinkedinIcon,
      href: "https://www.linkedin.com/company/sahel-and-west-africa-club-club-du-sahel-et-l-afrique-de-l-ouest/",
    },
  ];

  return (
    <div className={classes.FooterWrapper}>
      <div className={classes.Footer}>
        <div className={classes.FooterContent}>
          <div className={classes.FooterIcons}>
            {shareIcons.map(({ Icon, href }, i) => (
              <a key={i} href={href}>
                <Icon size={36} round={true} />
              </a>
            ))}
          </div>
          <div className={classes.FooterText}>
            {text}
            <br />
            <strong>africapolis@oecd.org</strong>
          </div>
        </div>
        <div className={classes.FooterHeader}>
          <a
            href="https://www.oecd.org/swac/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image
              imageWrapper={classes.FooterLogo}
              src={`${baseURL}images/logo/oecd_${locale}.png`}
              alt="Africapolis"
            />
          </a>
          <span>© 2022. SWAC/OECD</span>
          Photo: Ricci Shyrock
        </div>
      </div>
    </div>
  );
};
